.home-1,
.home-2,
.home-3 {
  img {
    width: 100%;
    max-width: 500px;
  }
}
.home-1 {
  padding: 100px 0 0px 0;
  z-index: 1;
  h1 {
    font-weight: bolder;
    font-size: clamp(32px, 8vw, 80px);
    line-height: clamp(32px, 8vw, 80px);
  }
  p {
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    max-width: 500px;
  }
  button {
    height: 50px !important;
    border-radius: 60px !important;
    background-color: @primary4-color;
    border-color: @menu-highlight-color;
    font-weight: 500;
    &:focus {
      background-color: @primary4-color;
      border-color: @menu-highlight-color;
    }
  }

  svg.rotate {
    margin-bottom: -18px;
  }
}

.home-2 {
  margin-top: 8px;
  z-index: 2;
  padding: 50px 0 0px 0;
  h3 {
    font-size: 34px;
    line-height: 38px;
    font-weight: bold;
    max-width: 500px;
  }
  p {
    max-width: 500px;
  }
}
.home-3 {
  h3 {
    font-size: 34px;
    font-weight: bold;
    max-width: 500px;
  }
  p {
    max-width: 500px;
  }
  img {
    border-radius: 40px;
  }
}
.home-5 {
  padding: 50px 0 40px 0;

  h3 {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
  }
  p.sub-title {
    padding: 0 12px;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
  }
  h4 {
    font-size: 22px;
    font-weight: bold;
  }

  @media @sm-min {
    padding: 90px 0 50px 0;
    p.sub-title {
      margin-bottom: 20px;
    }
  }
}
.home-6 {
  padding-bottom: 100px;
  h3 {
    text-align: center;
    margin-bottom: 10px;
    span {
      z-index: 2;
      position: relative;
      font-size: 34px;
      font-weight: bold;
      background: grey;
    }
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-tab {
    padding: 8px 12px;
    border: 1px solid black;
    border-radius: 5px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 20px;
  }
  .ant-tabs-tab .anticon {
    margin-right: 4px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    position: relative;
    background-color: #08104f;
    overflow: visible;
    z-index: 1000000;
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #08104f;
      bottom: -15px;
      text-align: center;
      transition: transform ease-in-out 250ms;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff;
    text-shadow: 0 0 0.25px currentColor;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: visible;
  }
  .ant-tabs-content-holder {
    padding: 20px;
    border: 1px solid #555;
    border-radius: 5px;
  }
}
.courses {
  img {
    border-radius: 40px;
  }
  ul {
    list-style-type: none;
    max-width: 500px;
    li {
      // margin-bottom: 10px;
      font-size: 20px;
    }
    li::before {
      content: "";
      display: inline-block;
      height: 32px;
      width: 32px;
      @baseDir: "";
      background-image: url("../../checkmark.svg");
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 0.5em;
      margin-bottom: -10px;
    }
  }
  .course-footer {
    display: flex;
    flex-direction: row;
    .c-f-divider {
      flex: 1;
      border-right: 1px solid #555555;
      text-align: center;
      &:first-child {
        border-left: 1px solid #555555;
      }
    }
    .c-f-title {
      font-weight: 600;
    }
  }
}
.contact {
  &-icons ul li {
    a {
      height: 60px;
      display: flex;
      align-items: center;
    }
  }

  &-val {
    font-size: 22px;
    color: #ffffff !important;
  }
  &-icon {
    font-size: 50px;
    margin-right: 20px;
    color: @primary4-color;
    transition: 300ms;
    &:hover {
      color: @primary-color;
    }
  }
}
.light-blue-btn {
  margin-top: 15px;
  background-color: @primary2-color;
  border-color: @primary2-color;
  &:hover {
    background-color: @primary4-color;
    border-color: @primary4-color;
  }
}
@media @md {
  .home-1 {
    padding-top: 70px;
    &-left {
      text-align: center;
    }
  }
}
