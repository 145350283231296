.header {
  position: fixed;
  width: 100vw;
  z-index: 1000;
  background-color: #08104f;
  transition: 500ms;
  &.scrolled {
    -webkit-box-shadow: 0 2px 8px #f0f1f2;
    -moz-box-shadow: 0 2px 8px #f0f1f2;
    box-shadow: 0 2px 8px #f0f1f2;
    transition: 500ms;
  }
  nav {
    height: @header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 150px;
    }
    .ant-dropdown-link {
      margin-left: 10px;
      img {
        width: 32px;
      }
      // span.anticon {
      //   margin-left: 5px;
      // }
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      height: @header-height;
      @media @md {
        .link {
          display: none;
        }
      }
      .avatar-item {
        padding: 0 20px;
      }
      li {
        margin: 0 12px;
        a {
          position: relative;
          color: @nav-color;
          font-weight: 600;
          &:hover {
            color: @hover-nav-color;
          }
        }
        a.active {
          color: @hover-nav-color;
        }
        a.active::after {
          content: "";
          display: block;
          height: 3px;
          background: @hover-nav-color;
          position: absolute;
          bottom: -0.7em;
          left: 0;
          right: 0;
          transform: scale(1, 1);
          transition: transform ease-in-out 250ms;
        }
        .nav-login-btn {
          &:hover {
            background: @hover-nav-color;
          }
        }
      }
      li:last-child {
        margin-right: 0;
      }
    }
  }
}
.ant-drawer-content {
  margin-top: @header-height;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-drawer-body {
  padding: 0;
  border-top: 1px solid lightgrey;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  font-weight: bold;
  font-size: 19px;
}
.ant-menu-inline .ant-menu-item {
  margin: 0;
}
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  text-align: center;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  padding: 0;
  font-size: 14px;
}
.mobile-drawer {
  visibility: hidden;
}
@media @md {
  .mobile-drawer {
    visibility: visible;
  }
}
