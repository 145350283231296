.fancy-burger {
  position: relative;
  width: @burger-width;
  height: @burger-height;
  border: 0;
  background: transparent;
  outline: none;
  cursor: pointer;
}
@media @md-min {
  .show-burger {
    display: none;
  }
}
.rectangle {
  position: absolute;
  height: @rectangle-height;
  background: @nav-color;
  border-radius: @rectangle-radius;
  transition: transform @burger-transition;
}

.rectangle--small {
  width: ~"calc(@{burger-width} / 2)";
}

.rectangle--top {
  top: 0;
  left: 0;
  transform-origin: top left;
}

.rectangle--top.open {
  transform: translateX(@burger-translate) rotate(45deg);
}

.rectangle--middle {
  top: 50%;
  left: 0;
  width: @burger-width;
  transform: translatey(-50%);
}

.rectangle--middle.open {
  transform: translatey(-50%) rotate(-45deg);
}

.rectangle--bottom {
  bottom: 0;
  right: 0;
  transform-origin: bottom right;
}

.rectangle--bottom.open {
  transform: ~"translateX(calc(-1 * @{burger-translate})) rotate(45deg)";
}
