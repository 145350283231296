html {
  scroll-behavior: smooth;
}
body {
  color: @text-color;
  background: @bg-color;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* width #d01345; */
::-webkit-scrollbar {
  width: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  /* box-shadow: inset 0 0 5px grey;
      border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
ul {
  list-style-type: none;
  margin: 0;
}
ol {
  list-style-type: none;
  counter-reset: ordered;
  margin-left: 70px;
  margin-top: 20px;
  max-width: 500px;
  li {
    margin-bottom: 18px;
    font-size: 20px;
  }

  li::before {
    border: 2px solid @primary2-color;
    border-radius: 50%;
    color: @primary4-color;
    content: counter(ordered);
    counter-increment: ordered;
    display: inline-block;
    font-weight: 500;
    margin-left: -70px;
    margin-top: -7px;
    padding: 5px 14px;
    position: absolute;
  }
}

a {
  text-decoration: none;
}
blockquote {
  margin: 0;
}
h1,
h3 {
  margin: 0;
}
p {
  margin: 0;
  font-size: 20px;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-60 {
  padding-bottom: 60px !important;
}
.pb-70 {
  padding-bottom: 70px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 15px;
}
.content-area {
  padding-top: @header-height;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
}
.card {
  box-sizing: border-box;
  margin: 0;
  padding: 30px;
  font-size: 14px;
  list-style: none;
  position: relative;
  background: #fff;
  border-radius: 5px;
}
.text-light-blue {
  color: @primary3-color;
}
.text-gold {
  color: @menu-highlight-color;
}
.bg-light-blue {
  background-color: #d1dff0;
}
.bg-blue {
  background-color: @primary2-color;
}
.bg-white {
  background-color: #ffffff;
}
.required {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @primary-color;
  min-height: 60px;
  color: @primary2-color;
  p {
    font-size: clamp(15px, 2.5vw, 18px);
    font-weight: bold;
  }
}

.go-top {
  font-size: 24px;
  color: @primary4-color;
  padding: 7px;
  background-color: blue;
  border-radius: 2px;
}
.svg {
  height: 20vh;
  width: 100%;
  display: block;
  z-index: 1;
}
.flip {
  transform: scaleX(-1);
}
.rotate {
  transform: rotate(180deg);
}
.text-center {
  text-align: center;
}
.underline {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &-dot {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: @primary2-color;
  }
  &-line {
    width: 60px;
    height: 5px;
    margin: 0 2px;
    border-radius: 40px;
    background-color: @primary2-color;
  }
}
// Loader
.block-loader {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.block-loader__spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.6);

  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@media @md {
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
}
@media @md-min {
  .col-left {
    padding-right: 20px;
  }
  .col-right {
    padding-left: 20px;
  }
}
.shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 500ms;
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
button.ant-btn {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(204, 140, 140, 0.23);
}
.ant-input {
  border-radius: 5px;
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 17px;
  font-weight: 500;
}
