@primary-color: #08104f;
@primary2-color: #598fd2;
@primary3-color: #dff1fd;
@primary4-color: #f19f38;
@text-selection-bg: fade(@primary-color, 40%);
@menu-item-font-size: 18px;
@drawer-bg: #08104f;
@menu-bg: #08104f;
@menu-item-color: #ffffff;
@menu-inline-toplevel-item-height: 60px;
@menu-item-active-border-width: 7px;
@menu-highlight-color: #ffe568;
@menu-item-height: 60px;
@form-item-label-font-size: 18px;
@form-vertical-label-padding: 0 0 5px;
@zindex-dropdown: 1050;
@menu-dark-item-active-bg: red;
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: #555555; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

@button-text: #fcf8ff;
@primary-gradient: linear-gradient(180deg, #ffffff 0%, #c8a1d7 100%);
@secondary-gradient: linear-gradient(180deg, #ffffff 0%, #ecfff1 100%);
@bg-color: #fcf8ff;
@phone: ~"only screen and (max-width: 440px)";
@sm: ~"only screen and (max-width: 576px)";
@md: ~"only screen and (max-width: 768px)";
@phone-min: ~"only screen and (min-width: 350px)";
@sm-min: ~"only screen and (min-width: 577px)";
@md-min: ~"only screen and (min-width: 769px)";

@header-height: 80px;

// burger menu
@burger-width: 30px;
// ~"calc(100% - 30px)"
@burger-height: ~"calc(@{burger-width} * 0.8)";
@rectangle-height: ~"calc(@{burger-width} * 0.1)";
@rectangle-radius: ~"calc(@{burger-width} * 0.04)";
@burger-translate: ~"calc(@{burger-width} * 0.17)";
@burger-transition: 0.65s;
@nav-color: #ffffff;
@hover-nav-color: #ffe568;
@keyframes loader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
